<template>
  <div class="pb-10">
    <v-row justify="center" align="start" no-gutters class="mb-3">
      <v-col cols="12" lg="12">
        <v-card style="min-height: 100vh">
          <v-card-title> Tutoriais </v-card-title>
          <span style="margin-left: 16px">Em breve...</span>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    user: {},
    userData: {},
  }),
  computed: {
    isTherapist() {
      return this.$store.getters["login/isTherapist"];
    },
  },
  created() {
    this.redirectToWebsite();
  },
  methods: {
    redirectToWebsite() {
      window.open("https://www.appbio.com.br/tutoriais", "_blank");
      this.$router.push({ path: "/home" });
    },
  },
};
</script>

<style></style>
